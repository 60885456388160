@media (min-width: 600px) {
  .one-auth-xuWWY {
    max-width: 960px;
  }
}
.one-auth-xuWWY {
  color: var(--oneauth-font-color);
  font-family: var(--oneauth-font-family-hind);
  padding: 0;
}
.one-auth-xuWWY * {
  box-sizing: border-box;
}
.button-Xkz1j {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  font-family: var(--oneauth-font-family-canal);
  font-size: var(--oneauth-font-size-18);
  opacity: 1;
  padding: 12px 50px;
  text-transform: uppercase;
  width: 100%;
}
.button-Xkz1j * {
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .button-Xkz1j {
    border-radius: 0;
    border-radius: initial;
    display: inline-block;
    width: auto;
  }
}
.button-Xkz1j:focus {
  outline: none;
}
.button--primary-jmibI {
  background-color: var(--oneauth-button-primary-bg);
  color: var(--oneauth-button-primary-color);
}
.button--primary-jmibI:not(.button--disabled-aYqok):hover {
  background-color: var(--oneauth-button-primary-bg-hover);
}
.button--primary--hind-GKETW {
  font-family: var(--oneauth-font-family-hind);
  font-style: normal;
  text-transform: none;
}
.button--secondary-i7JXY {
  background-color: var(--oneauth-button-secondary-bg);
  color: var(--oneauth-button-secondary-color);
  font-family: var(--oneauth-button-secondary-font);
  font-weight: var(--oneauth-button-secondary-weight);
  text-transform: var(--oneauth-button-secondary-case);
}
@media (min-width: 600px) {
  .button--secondary-i7JXY {
    border-radius: 4px;
  }
}
.button--secondary-i7JXY:not(.button--disabled-aYqok):hover {
  background-color: var(--oneauth-button-secondary-bg-hover);
}
.button--disabled-aYqok {
  cursor: not-allowed;
  opacity: 0.5;
}
.button--link-_YF0T {
  text-align: center;
  text-decoration: none;
}
.text-RmNfB {
  --oneauth-heading-font-size: var(--oneauth-font-size-22);
}
@media (min-width: 600px) {
  .text-RmNfB {
    --oneauth-heading-font-size: var(--oneauth-font-size-26);
  }
}
.text-RmNfB {
  color: var(--oneauth-font-color);
  font-family: var(--oneauth-font-family-hind);
  font-size: var(--oneauth-font-size-16);
  font-weight: var(--oneauth-font-weight-regular);
  line-height: 1.5;
  margin: 0;
}
.text-RmNfB * {
  box-sizing: border-box;
}
.text--heading-NUIhN {
  font-size: var(--oneauth-heading-font-size);
  font-weight: var(--oneauth-font-weight-semi);
  line-height: 1;
}
.text--footnote-_Owcu {
  color: var(--oneauth-color-grey-84);
  line-height: 1;
}
.text--footnote-_Owcu,
.text--subhead-H1MCP {
  font-size: var(--oneauth-font-size-12);
}
.text--subhead-H1MCP {
  line-height: 1.25;
}
.text--small-lIgzI {
  font-size: var(--oneauth-font-size-14);
  font-weight: var(--oneauth-font-weight-regular);
  line-height: 1.6;
}
.login-screen__subtitle-N9M6j {
  margin-bottom: 30px;
}
.title-xYuav {
  box-sizing: border-box;
  color: var(--oneauth-font-color);
  font-family: var(--oneauth-font-family-canal);
  font-size: var(--oneauth-font-size-24);
  line-height: 1.13;
  margin: 0 0 30px;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  .title-xYuav {
    font-size: var(--oneauth-font-size-32);
    margin-bottom: 36px;
  }
}
@media (min-width: 600px) {
  .congratulations-r3a88 {
    margin-left: 80px;
  }
}
.congratulations__text-JSiUH {
  margin-bottom: 30px;
}
.icon-L6xBL {
  align-items: center;
  display: flex;
  justify-content: center;
}
.icon--opacity-30-npfdh {
  opacity: 0.3;
}
.alert-Sj8kF {
  align-items: center;
  border-radius: 2px;
  color: var(--oneauth-color-black);
  display: flex;
  flex-direction: row;
  font-family: var(--oneauth-font-family-hind);
  padding: 20px;
}
.alert-Sj8kF * {
  box-sizing: border-box;
}
.alert--info-Qt0pZ {
  background-color: var(--oneauth-alert-bg-info);
}
.alert--valid-i0xdu {
  background-color: var(--oneauth-alert-bg-valid);
}
.alert--warning-Iph04 {
  background-color: var(--oneauth-alert-bg-warning);
}
.alert--unvalid-xsg8i {
  background-color: var(--oneauth-alert-bg-unvalid);
}
.alert--small-VMtMY {
  padding: 10px;
}
.alert__wrapper-content-M0CX9 {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-left: 20px;
}
.alert__wrapper-content--small-nU4f0 {
  margin-left: 10px;
}
.alert__content-PhBIr {
  display: inline;
  margin-right: 5px;
}
.checkbox-yF8Rq {
  align-items: flex-start;
  color: var(--oneauth-font-color);
  display: flex;
  font-family: var(--oneauth-font-family-hind);
  line-height: 1;
}
.checkbox-yF8Rq * {
  box-sizing: border-box;
}
.checkbox--footnote-HNrKY {
  display: block;
}
.checkbox__content--footnote-KNVqz {
  display: inline;
}
.checkbox__checkboxElement-TdGcD {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: var(--oneauth-color-white);
  box-shadow: none;
  cursor: pointer;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
.checkbox__checkboxElement-TdGcD[focus-within] {
  outline: none;
}
.checkbox__checkboxElement-TdGcD:focus-within {
  outline: none;
}
.checkbox__checkboxElement-TdGcD:before {
  background: var(--oneauth-code-checkbox-bg);
  border: 2px solid var(--oneauth-code-checkbox-border);
  border-radius: 2px;
  content: '';
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
}
.checkbox__checkboxElement-TdGcD:checked:before {
  background-color: var(--oneauth-code-checkbox-bg-checked);
  background-image: url(2846e2d36cc9daf17853.svg);
  background-position: 50%;
  background-repeat: no-repeat;
}
.checkbox__checkboxElement--footnote-FvmR1 {
  line-height: 1.3;
  margin-right: 8px;
}
.checkbox__checkboxElement--footnote-FvmR1:before {
  border: 1px solid var(--oneauth-color-black);
  border-radius: 3px;
  height: 10px;
  opacity: 0.3;
  width: 10px;
}
.checkbox__checkboxElement--footnote-FvmR1:checked:before {
  background-color: var(--oneauth-color-black);
  background-size: 80%;
}
.checkbox__checkboxElement--error-VgipT:before {
  border-color: var(--oneauth-color-red-error);
}
.checkbox__checkboxElement--disabled-mNfhW:before {
  background-color: var(--oneauth-color-black);
  border-color: var(--oneauth-color-black);
  opacity: 0.3;
}
.checkbox__label-OOLd4 {
  display: inline;
}
.link-button-O01t8 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: var(--oneauth-font-family-hind);
  font-size: var(--oneauth-font-size-16);
  font-weight: var(--oneauth-font-weight-semi);
  line-height: 1.5;
  margin-right: 20px;
  padding: 0;
  text-decoration: none;
  text-transform: none;
}
.link-button-O01t8 * {
  box-sizing: border-box;
}
.link-button-O01t8:focus {
  outline: none;
}
.link-button--primary-tpBah {
  background-color: var(--oneauth-link-button-primary-bg);
  color: var(--oneauth-link-button-primary-color);
}
.link-button--primary-tpBah:hover {
  color: var(--oneauth-link-button-primary-color-hover);
}
.link-button--secondary-UPrQ3 {
  color: var(--oneauth-link-button-secondary-color);
}
.link-button--secondary-UPrQ3:hover {
  color: var(--oneauth-link-button-secondary-color-hover);
}
.link-button--small-Z2fe6 {
  font-size: var(--oneauth-font-size-12);
  line-height: 1;
}
.link-button__arrow-TNE7E {
  border-style: solid;
  border-width: 2.5px 2.5px 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  left: 0;
  margin-left: 5px;
  position: relative;
  top: 8px;
  transform: rotate(45deg);
  vertical-align: top;
  width: 8px;
}
.cgu-ddC_Y {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  max-width: 480px;
}
@media (min-width: 600px) {
  .cgu-ddC_Y {
    margin: 40px 0 60px;
  }
}
.cgu__link-QOL0R {
  margin-left: 4px;
}
.cgu__alert-IavsZ {
  margin-top: 10px;
}
.footnotes-XJm78 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.footnotes__content-ZxEc9 {
  line-height: 1.3;
  margin-top: 5px;
}
.spinner-GFbdH {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10;
}
.spinner__circle-j4pFg {
  border-color: var(--oneauth-color-grey-14) var(--oneauth-color-grey-98)
    var(--oneauth-color-grey-14) var(--oneauth-color-grey-14);
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  height: 100%;
  width: 100%;
}
.spinner__circle-j4pFg * {
  box-sizing: border-box;
}
.spinner__circle-j4pFg {
  animation: rotate 1s linear infinite;
}
.spinner__circle--withKeyboard-TLM46 {
  margin-top: 20px;
}
@media (min-width: 600px) {
  .spinner__circle--withKeyboard-TLM46 {
    margin-top: 0;
  }
}
.spinner__circle--hidden-sKZqk {
  display: none;
}
.input-QkyBS {
  color: var(--oneauth-color-black);
  font-family: var(--oneauth-font-family-hind);
  width: 100%;
}
.input-QkyBS * {
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .input-QkyBS {
    width: auto;
  }
}
.input-QkyBS[focus-within] label {
  transform: translate(10px) scale(0.625);
}
.input-QkyBS:focus-within label {
  transform: translate(10px) scale(0.625);
}
.input__inputElement-eVwu5 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 1px solid var(--oneauth-color-grey-84);
  padding: 12px 20px 0;
  width: 100%;
}
@media (min-width: 600px) {
  .input__inputElement-eVwu5 {
    width: var(--oneauth-input-width);
  }
}
.input__inputElement-eVwu5::-webkit-inner-spin-button,
.input__inputElement-eVwu5::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.input__inputElement-eVwu5:focus {
  outline: 2px solid var(--oneauth-color-grey-14);
}
.input__inputElement--error-caLro {
  outline: 2px solid var(--oneauth-color-red-error);
}
.input__inputElement--disabled-o8aVW {
  background-color: var(--oneauth-color-grey-96);
}
.input__label-vOCUo {
  color: var(--oneauth-color-grey-60);
  font-size: var(--oneauth-font-size-16);
  line-height: 30px;
  padding: 12px 16px;
  pointer-events: none;
  position: absolute;
  transform: scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}
.input__label--hasValue-HVz46 {
  transform: translate(10px) scale(0.625);
}
.input__label--disabled-GDglZ {
  color: var(--oneauth-color-grey-84);
}
.base-input-UpKe3 {
  background-color: var(--oneauth-color-white);
  border-radius: 2px;
  color: var(--oneauth-color-black);
  font-size: var(--oneauth-font-size-16);
  height: var(--oneauth-input-height);
}
.popover-O3FR_ {
  background-color: var(--oneauth-color-grey-92);
  color: var(--oneauth-color-black);
  font-family: var(--oneauth-font-family-hind);
  opacity: 0;
  padding: 10px;
  transform: translate3d(-15px, -20px, 0);
  transform-origin: top center;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  width: calc(100vw - var(--oneauth-main-wrapper-horizontal-padding) * 2);
  z-index: 100;
}
.popover-O3FR_ * {
  box-sizing: border-box;
}
[data-enter] .popover-O3FR_ {
  opacity: 1;
  transform: translate3d(-15px, 0, 0);
}
@media (min-width: 600px) {
  [data-enter] .popover-O3FR_ {
    transform: translateZ(0);
  }
  .popover-O3FR_ {
    transform: translate3d(0, -20px, 0);
    width: 345px;
  }
}
.popover__reference-vBjqj {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.popover__arrow-yXPyR {
  margin-left: 15px;
  margin-top: -2px;
}
.popover__arrow-yXPyR svg {
  fill: var(--oneauth-color-grey-92);
}
@media (min-width: 600px) {
  .popover__arrow-yXPyR {
    margin-left: 0;
  }
}
.clientNumberInput-rQTxN {
  align-items: center;
  display: flex;
}
.clientNumberInput__icon-ImFa3 {
  margin-left: -36px;
}
@media (min-width: 600px) {
  .clientNumberInput__alert-MeTZd,
  .clientNumberInput__inputElement-V3iro {
    box-sizing: border-box;
    width: var(--oneauth-register-form-input-width);
  }
}
.emailIdInput-ngtoJ {
  margin-bottom: 20px;
  width: 100%;
}
.emailIdInput-ngtoJ * {
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .emailIdInput__element-vxLlU {
    width: var(--oneauth-register-form-input-width);
  }
}
.select-right-Odh9H {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: var(--oneauth-font-family-hind);
  height: var(--oneauth-input-height);
  position: relative;
  width: var(--oneauth-select-right-input-width-mobile);
}
.select-right-Odh9H * {
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .select-right-Odh9H {
    justify-content: center;
    width: var(--oneauth-select-right-input-width-desktop);
  }
}
.select-right__value-QDpky {
  color: var(--oneauth-color-grey-80);
  font-size: var(--oneauth-font-size-16);
  margin-right: 30px;
}
.select-right__icon-znxFU {
  position: absolute;
  right: 15px;
  top: 13px;
}
.select-ZJEG8 {
  color: var(--oneauth-color-black);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: var(--oneauth-font-family-hind);
  justify-content: space-between;
}
.select-ZJEG8 * {
  box-sizing: border-box;
}
.select__combobox-mdzN5 {
  background-color: var(--oneauth-color-white);
  border-radius: 2px;
  color: var(--oneauth-color-black);
  display: flex;
  font-size: var(--oneauth-font-size-16);
  height: var(--oneauth-input-height);
  justify-content: space-between;
  outline: 1px solid var(--oneauth-color-grey-84);
  position: relative;
}
@media (min-width: 600px) {
  .select__combobox-mdzN5 {
    width: var(--oneauth-register-form-input-width);
  }
}
.select__combobox--error-N7rOL {
  outline: 2px solid var(--oneauth-color-red-error);
}
.select__combobox-mdzN5[focus-within] {
  outline: 2px solid var(--oneauth-color-grey-14);
}
.select__combobox-mdzN5:focus-within {
  outline: 2px solid var(--oneauth-color-grey-14);
}
.select__input-BKSpS,
.select__input-BKSpS:focus {
  border: none;
  outline: none;
}
.select__input-BKSpS.select__input-BKSpS::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.select__options-ARfFZ {
  border-left: 1px solid var(--oneauth-color-grey-84);
  border-right: 1px solid var(--oneauth-color-grey-84);
  box-shadow: 0 4px 10px 0 var(--oneauth-color-grey-90);
  list-style-type: none;
  margin: 0;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0;
  scrollbar-width: none;
}
.select__options-ARfFZ.select__options-ARfFZ::-webkit-scrollbar {
  display: none;
}
@media (min-width: 600px) {
  .select__options-ARfFZ {
    width: var(--oneauth-register-form-input-width);
  }
}
.select__option-TqA7P {
  align-items: center;
  border-bottom: 1px solid var(--oneauth-color-grey-84);
  cursor: pointer;
  display: flex;
  font-size: var(--oneauth-font-size-16);
  height: var(--oneauth-input-height);
  list-style-type: none;
  padding: 0 20px;
}
.select__option-TqA7P:focus,
.select__option-TqA7P:hover {
  background-color: var(--oneauth-color-grey-98);
}
.select__option-value-ZrpUE {
  margin-left: 5px;
  opacity: 0.5;
}
.phoneIdInput-YM0L1 {
  margin-bottom: 20px;
  width: 100%;
}
.phoneIdInput-YM0L1 * {
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .phoneIdInput__element-hswOm {
    width: var(--oneauth-register-form-input-width);
  }
}
.passwordValidation-sIlZ6 {
  color: var(--oneauth-font-color);
  font-family: var(--oneauth-font-family-hind);
}
.passwordValidation-sIlZ6 * {
  box-sizing: border-box;
}
.passwordValidation-sIlZ6 p:nth-child(2n) {
  margin: 2px 0;
}
.passwordValidation--icon-pigme {
  height: 16px;
  margin-left: 4px;
  vertical-align: middle;
  width: 16px;
}
.passwordInput-iij0s {
  align-items: center;
  color: var(--oneauth-color-black);
  display: flex;
  font-family: var(--oneauth-font-family-hind);
}
.passwordInput-iij0s * {
  box-sizing: border-box;
}
.passwordInput__input-slBDe {
  width: 100%;
}
@media (min-width: 600px) {
  .passwordInput__input-slBDe {
    width: var(--oneauth-register-form-input-width);
  }
}
.passwordInput__icon-_i0Dl {
  cursor: pointer;
}
.passwordInput__icon-_i0Dl div {
  margin-left: -36px;
}
.passwordInput__icon--emptyPassword-R_nsH {
  fill: var(--oneauth-color-black-30);
}
.passwordInput__validationWrapper-lp0rV {
  margin: 8px 0 20px 20px;
}
.register-alert-SIiPa {
  margin: -30px 0 50px;
}
.register-alert-SIiPa p {
  margin-right: 10px;
}
.register-form-Pb5Xk {
  position: relative;
}
.register-form__ctas-xAxTW {
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .register-form__ctas-xAxTW {
    flex-direction: row;
    justify-content: space-between;
  }
}
.register-form__back-l_SA_ {
  display: none;
}
@media (min-width: 600px) {
  .register-form__back-l_SA_ {
    display: block;
  }
}
.register-form__inputs-Nx4qx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
}
.register-form__alert-kKor6 {
  margin: -30px 0 50px;
}
.register-form__alert-kKor6 .link-button-xbwh8 {
  margin-right: 20px;
}
.register-screen-rs3bx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.register-screen__subtitle-NGOkE {
  margin-bottom: 30px;
}
.tab-gtk8G {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--oneauth-color-grey-70);
  color: var(--oneauth-color-grey-70);
  cursor: pointer;
  display: flex;
  font-family: var(--oneauth-font-family-canal);
  font-size: 14px;
  font-weight: 300;
  height: 35px;
  outline: 0;
  padding: 4px 0 0;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
@media (min-width: 600px) {
  .tab-gtk8G {
    font-size: 24px;
    height: 40px;
    padding-top: 0;
  }
}
.tab--selected-gtVda {
  border-color: var(--oneauth-color-grey-14);
  color: var(--oneauth-color-grey-14);
}
.tab--selected-gtVda:hover {
  cursor: auto;
}
.tab-gtk8G:hover {
  color: var(--oneauth-color-grey-14);
}
.tablist-Bgfi6 {
  display: flex;
}
.tabpanel-L4eTQ {
  padding-top: 30px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@font-face {
  font-family: canalbolditalique;
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/CanalBoldItalique.woff2) format('woff2'),
    url(assets/fonts/CanalBoldItalique.woff) format('woff');
}
@font-face {
  font-family: hind;
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/hind-regular.woff2) format('woff2'),
    url(assets/fonts/hind-regular.woff) format('woff');
}
@font-face {
  font-family: hind;
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/hind-semibold.woff2) format('woff2'),
    url(assets/fonts/hind-semibold.woff) format('woff');
}
@font-face {
  font-family: hind;
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/hind-bold.woff2) format('woff2'),
    url(assets/fonts/hind-bold.woff) format('woff');
}
:root {
  --oneauth-color-black: #000;
  --oneauth-color-black-30: rgba(0, 0, 0, 0.3);
  --oneauth-color-black-02: rgba(0, 0, 0, 0.02);
  --oneauth-color-black-50: rgba(0, 0, 0, 0.5);
  --oneauth-color-black-09: rgba(0, 0, 0, 0.09);
  --oneauth-color-red: #ec3654;
  --oneauth-color-red-error: #eb3d43;
  --oneauth-color-red-error-10: rgba(235, 61, 67, 0.1);
  --oneauth-color-pink: #ee4965;
  --oneauth-color-green: #0cc07b;
  --oneauth-color-green-10: rgba(12, 192, 123, 0.1);
  --oneauth-color-yellow: #f5a905;
  --oneauth-color-yellow-10: rgba(245, 169, 5, 0.1);
  --oneauth-color-white: #fff;
  --oneauth-color-white-70: hsla(0, 0%, 100%, 0.7);
  --oneauth-color-white-50: hsla(0, 0%, 100%, 0.5);
  --oneauth-color-grey-14: #242424;
  --oneauth-color-grey-84: #d6d6d6;
  --oneauth-color-grey-92: #ebebeb;
  --oneauth-color-grey-96: #f5f5f5;
  --oneauth-color-grey-98: #fafafa;
  --oneauth-color-grey-21: #363636;
  --oneauth-color-grey-50: grey;
  --oneauth-color-grey-60: #999;
  --oneauth-color-grey-70: #b3b3b3;
  --oneauth-color-grey-10: #1a1a1a;
  --oneauth-color-grey-20: #333;
  --oneauth-color-gold-roti: #bea03d;
  --oneauth-color-gold-alpine: #ba942e;
  --oneauth-color-gold-kumera: #986d23;
  --oneauth-color-gold-dark-primary: #89621f;
  --oneauth-color-gold-dark-secondary: #7a571c;
  --oneauth-color-gold-dark-tertiary: #6a4c18;
  --oneauth-color-gold-dark-button: #79561b;
  --oneauth-font-family-hind: hind, 'San Francisco', 'Roboto', 'Arial', sans-serif;
  --oneauth-font-family-canal: canalbolditalique, sans-serif;
  --oneauth-font-weight-regular: 300;
  --oneauth-font-weight-semi: 600;
  --oneauth-font-weight-bold: 800;
  --oneauth-font-size-32: 32px;
  --oneauth-font-size-26: 26px;
  --oneauth-font-size-24: 24px;
  --oneauth-font-size-22: 22px;
  --oneauth-font-size-18: 18px;
  --oneauth-font-size-16: 16px;
  --oneauth-font-size-14: 14px;
  --oneauth-font-size-12: 12px;
  --oneauth-main-wrapper-horizontal-padding: 15px;
  --oneauth-transition-duration: 0.5s;
  --oneauth-select-right-input-width-desktop: 100px;
  --oneauth-select-right-input-width-mobile: 90px;
  --oneauth-input-height: 50px;
  --oneauth-input-width: 380px;
  --oneauth-register-form-input-width: calc(
    var(--oneauth-input-width) + var(--oneauth-select-right-input-width-desktop)
  );
  --oneauth-font-color: var(--oneauth-color-black);
  --oneauth-button-primary-color: var(--oneauth-color-white);
  --oneauth-button-primary-bg: var(--oneauth-color-red);
  --oneauth-button-primary-bg-hover: var(--oneauth-color-pink);
  --oneauth-button-secondary-color: var(--oneauth-color-black);
  --oneauth-button-secondary-bg: var(--oneauth-color-grey-92);
  --oneauth-button-secondary-bg-hover: var(--oneauth-color-grey-96);
  --oneauth-button-secondary-font: var(--oneauth-font-family-canal);
  --oneauth-button-secondary-case: uppercase;
  --oneauth-button-secondary-weight: var(--oneauth-font-weight-regular);
  --oneauth-code-input-color: var(--oneauth-color-black);
  --oneauth-code-input-color-placeholder: var(--oneauth-color-black-50);
  --oneauth-code-input-border: var(--oneauth-color-grey-84);
  --oneauth-code-input-bg: var(--oneauth-color-white);
  --oneauth-code-input-focus-bg: var(--oneauth-color-white);
  --oneauth-code-input-focus-border: var(--oneauth-color-grey-14);
  --oneauth-code-input-width: 40px;
  --oneauth-code-checkbox-bg: var(--oneauth-color-white);
  --oneauth-code-checkbox-border: var(--oneauth-color-grey-14);
  --oneauth-code-checkbox-bg-checked: var(--oneauth-color-grey-14);
  --oneauth-alert-bg-info: var(--oneauth-color-grey-96);
  --oneauth-alert-bg-valid: var(--oneauth-color-green-10);
  --oneauth-alert-bg-warning: var(--oneauth-color-yellow-10);
  --oneauth-alert-bg-unvalid: var(--oneauth-color-red-error-10);
  --oneauth-link-button-primary-color: var(--oneauth-color-red);
  --oneauth-link-button-primary-color-hover: var(--oneauth-color-pink);
  --oneauth-link-button-primary-bg: transparent;
  --oneauth-link-button-secondary-color: var(--oneauth-color-black);
  --oneauth-link-button-secondary-color-hover: var(--oneauth-color-grey-21);
}
:root [data-theme~='telecomitalia'] {
  --oneauth-font-color: var(--oneauth-color-white);
  --oneauth-button-primary-color: var(--oneauth-color-grey-10);
  --oneauth-button-primary-bg: var(--oneauth-color-white);
  --oneauth-button-primary-bg-hover: var(--oneauth-color-white-70);
  --oneauth-button-secondary-color: var(--oneauth-color-white);
  --oneauth-button-secondary-bg: var(--oneauth-color-gold-dark-button);
  --oneauth-button-secondary-bg-hover: var(--oneauth-color-gold-dark-button);
  --oneauth-button-secondary-font: var(--oneauth-font-family-hind);
  --oneauth-button-secondary-case: none;
  --oneauth-button-secondary-weight: var(--oneauth-font-weight-semi);
  --oneauth-code-input-color: var(--oneauth-color-white);
  --oneauth-code-input-color-placeholder: var(--oneauth-color-white-50);
  --oneauth-code-input-border: var(--oneauth-color-gold-dark-secondary);
  --oneauth-code-input-bg: var(--oneauth-color-gold-dark-secondary);
  --oneauth-code-input-focus-bg: var(--oneauth-color-gold-dark-secondary);
  --oneauth-code-input-focus-border: var(--oneauth-color-white-50);
  --oneauth-code-input-width: 50px;
  --oneauth-code-checkbox-bg: var(--oneauth-color-gold-dark-secondary);
  --oneauth-code-checkbox-border: var(--oneauth-color-gold-dark-secondary);
  --oneauth-code-checkbox-bg-checked: var(--oneauth-color-gold-dark-secondary);
  --oneauth-alert-bg-info: var(--oneauth-color-gold-dark-secondary);
  --oneauth-alert-bg-valid: var(--oneauth-color-gold-dark-secondary);
  --oneauth-alert-bg-warning: var(--oneauth-color-gold-dark-secondary);
  --oneauth-alert-bg-unvalid: var(--oneauth-color-gold-dark-secondary);
  --oneauth-link-button-primary-color: var(--oneauth-color-white);
  --oneauth-link-button-primary-color-hover: var(--oneauth-color-white-70);
  --oneauth-link-button-bg: var(--oneauth-color-gold-dark-secondary);
  --oneauth-link-button-secondary-color: var(--oneauth-color-white);
  --oneauth-link-button-secondary-color-hover: var(--oneauth-color-white-70);
}

.CodeInputFragment-lIGDz {
  background-color: var(--oneauth-code-input-bg);
  border: 1px solid var(--oneauth-code-input-border);
  border-radius: 2px;
  caret-color: transparent;
  color: var(--oneauth-code-input-color);
  font-size: var(--oneauth-font-size-26);
  height: 50px;
  text-align: center;
  width: var(--oneauth-code-input-width);
}
.CodeInputFragment-lIGDz:not(:last-child) {
  margin-right: 15px;
}
.CodeInputFragment-lIGDz::-moz-placeholder {
  color: transparent;
  font-size: var(--oneauth-font-size-22);
}
.CodeInputFragment-lIGDz::placeholder {
  color: transparent;
  font-size: var(--oneauth-font-size-22);
}
.CodeInputFragment-lIGDz:focus {
  border: 1.5px solid var(--oneauth-code-input-focus-border);
  outline: 0;
}
.CodeInputFragment-lIGDz:focus::-moz-placeholder {
  color: var(--oneauth-code-input-color-placeholder);
}
.CodeInputFragment-lIGDz:focus::placeholder {
  color: var(--oneauth-code-input-color-placeholder);
}
.codeInput-koxv9 {
  display: flex;
  font-family: var(--oneauth-font-family-hind);
}
.codeInput-koxv9 * {
  box-sizing: border-box;
}
.radio-button-j5k6p {
  align-items: center;
  color: var(--oneauth-color-black);
  cursor: pointer;
  display: flex;
  font-family: var(--oneauth-font-family-hind);
  justify-content: space-between;
}
.radio-button-j5k6p * {
  box-sizing: border-box;
}
.radio-button-j5k6p label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}
.radio-button-j5k6p input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1.5px solid var(--oneauth-color-grey-14);
  border-radius: 50%;
  color: var(--oneauth-color-grey-14);
  cursor: pointer;
  flex-shrink: 0;
  height: 20px;
  margin-left: 20px;
  margin-top: 0;
  width: 20px;
}
.radio-button-j5k6p input[type='radio']:checked {
  border: 6px solid var(--oneauth-color-grey-14);
}
.radio-button-card-J6ojo {
  border: 1px solid var(--oneauth-color-grey-92);
  list-style-type: none;
  transition: background-color var(--oneauth-transition-duration) ease;
  width: 100%;
}
.radio-button-card-J6ojo * {
  box-sizing: border-box;
}
.radio-button-card-J6ojo:not(:last-child) {
  border-bottom-width: 0;
}
.radio-button-card-J6ojo:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.radio-button-card-J6ojo:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.radio-button-card--isSelected-hUMKR {
  border-color: transparent;
  outline: 2px solid var(--oneauth-color-grey-14);
}
.radio-button-card-J6ojo:hover {
  background-color: var(--oneauth-color-black-02);
  transition: background-color var(--oneauth-transition-duration) ease;
}
.radio-list-o8gw8 {
  padding-left: 0;
}
